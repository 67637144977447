import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_FEED_PATH } from '../paths';
import { toDomain, TransportOrderFeedResponseDto } from './dto/transportOrdersFeedGetDto';

export const useTransportOrdersFeedRepository = () => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['transportOrders', 'feed'],
        placeholderData: keepPreviousData,
        staleTime: 0,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_FEED_PATH}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const dto = await response.json() as TransportOrderFeedResponseDto;

            return toDomain(dto);
        },
    });

    return {
        ...query,
    };
};