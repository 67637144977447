import React, { useState } from 'react';
import { PlusLg } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { LoadingSkeletonTableRows } from '../../components/LoadingSkeletonTableRows';
import { PageTitle } from '../../components/PageTitle';
import { RefreshButton } from '../../components/RefreshButton';
import { useContractorsRepository } from '../../repository/contractors/useContractorsRepository';
import { ContractorsFilters } from './ContractorsFilters';
import { ContractorsTableHeader } from './ContractorsTableHeader';
import { ContractorTableRow } from './ContractorsTableRow';
import { NewContractorModal } from './NewContractorModal';
import { useContractorsFilters } from './useContractorsFilters';

export const Contractors = () => {
    const [showNewContractorForm, setShowNewContractorForm] = useState(false);

    const { filters, onFiltersChange } = useContractorsFilters();
    const { data: contractors, refetch, isLoading, isRefetching } = useContractorsRepository(filters);

    const handleShowNewContractorFormOpen = () => setShowNewContractorForm(true);
    const handleShowNewContractorFormClose = () => setShowNewContractorForm(false);

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-row">
                    <PageTitle>Podwykonawcy</PageTitle>
                    <RefreshButton
                        className="ms-2"
                        isRefreshing={isLoading || isRefetching}
                        refreshFunction={refetch}
                    />
                </div>
                <Button onClick={handleShowNewContractorFormOpen}><PlusLg />{' '}Nowy podwykonawca</Button>
            </div>
            <ContractorsFilters className="mb-2" filters={filters} onFiltersChange={onFiltersChange} />
            <Table striped bordered hover>
                <ContractorsTableHeader />
                <tbody>{!contractors ? <LoadingSkeletonTableRows rows={5} columns={7} /> :
                    contractors.map((contractor, index) => <ContractorTableRow
                        key={`contractor-${contractor.id}`}
                        index={index}
                        contractor={contractor}
                    />)}</tbody>
            </Table>
            {contractors?.length === 0 &&
                <p className="d-flex align-self-center">Brak podwykonawców.{' '}
                    <Button className="p-0 border-0 ms-2 text-decoration-none" variant="link" onClick={handleShowNewContractorFormOpen}>
                        Utwórz podwykonawcę
                    </Button>
                </p>}

            <NewContractorModal
                show={showNewContractorForm}
                onClose={handleShowNewContractorFormClose}
            />
        </>
    );
};
