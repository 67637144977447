import React from 'react';
import { TransportOrderFeedItem } from '../../domain/transportOrdersFeed';
import { TransportOrderFeedTableRow } from './TransportOrderFeedTableRow';
import './TransportOrderFeedTable.scss';


type TransportOrderFeedTableProps = {
    items: Array<TransportOrderFeedItem>;
    label: string;
    addressLabel: string;
    noItemsMessage: string;
    className?: string;
    dateIncluded: boolean;
    context: {
        shipmentType: 'loading' | 'unloading'
    };
}

export const TransportOrderFeedTable = ({
    items,
    label,
    addressLabel,
    noItemsMessage,
    dateIncluded,
    context,
    className,
}: TransportOrderFeedTableProps) => {
    const [transportOrderPopoverOpenId, setTransportOrderPopoverOpenId] = React.useState<string | null>(null);
    const headerClassName = 'grid-cell fw-bold text-center bg-white';

    return (
        <div className={className}>
            <h3>{label}</h3>
            {items.length > 0 && <div className={`d-grid gap-0 grid-table${dateIncluded ? '-with-date' : ''} mb-2`}>
                <div className={headerClassName}>#</div>
                <div className={headerClassName}>Status</div>
                {dateIncluded && <div className={headerClassName}>Data</div>}
                <div className={`${headerClassName} text-truncate text-nowrap`}>{addressLabel}</div>
                <div className={`${headerClassName} text-truncate text-nowrap`}>Podwykonawca</div>
                {items.map((item) =>
                    <TransportOrderFeedTableRow
                        key={item.id}
                        item={item}
                        dateIncluded={dateIncluded}
                        context={context}
                        popoverOpenId={transportOrderPopoverOpenId}
                        onPopoverOpen={(openId) => setTransportOrderPopoverOpenId(openId)}
                        onPopoverClose={() => setTransportOrderPopoverOpenId(null)}
                    />)
                }
            </div>}
            {items.length === 0 && <p className="d-flex align-self-center m-0">{noItemsMessage}</p>}
        </div>
    );
};
