import { TransportOrderFeedResponse } from '../../../domain/transportOrdersFeed';
import { GetTransportOrderDto, toDomain as transportOrderDtoToDomain } from '../../transport-orders/dto/getTransportOrderDto';

export type TransportOrderFeedStatus =
    | 'toLoad'
    | 'toUnload'
    | 'toUnloadAndNotYetLoaded'
    | 'loaded'
    | 'unloaded';

export type TransportOrderFeedResponseDto = {
    loading: TransportOrderFeedDto;
    unloading: TransportOrderFeedDto;
};


export type TransportOrderFeedDto = {
    forToday: Array<TransportOrderFeedItem>;
    forTomorrow: Array<TransportOrderFeedItem>;
    forYesterday: Array<TransportOrderFeedItem>;
    late: Array<TransportOrderFeedItem>;
};

export type TransportOrderFeedItem = {
    id: string;
    type: TransportOrderFeedStatus;
    orderId: string;
    contractor: GetTransportOrderDto['contractor'];
    transportOrder: GetTransportOrderDto;
    date: number;
    endDate: number;
    elements: Array<{
        address: string;
        date: number;
        endDate: number;
        completed: boolean;
    }>;
};

export const toDomain = (dto: TransportOrderFeedResponseDto): TransportOrderFeedResponse => ({
    loading: {
        forToday: dto.loading.forToday.map((feedItem) => ({
            ...feedItem,
            transportOrder: transportOrderDtoToDomain(feedItem.transportOrder)
        })),
        forTomorrow: dto.loading.forTomorrow.map((feedItem) => ({
            ...feedItem,
            transportOrder: transportOrderDtoToDomain(feedItem.transportOrder)
        })),
        forYesterday: dto.loading.forYesterday.map((feedItem) => ({
            ...feedItem,
            transportOrder: transportOrderDtoToDomain(feedItem.transportOrder)
        })),
        late: dto.loading.late.map((feedItem) => ({
            ...feedItem,
            transportOrder: transportOrderDtoToDomain(feedItem.transportOrder)
        }))
    },
    unloading: {
        forToday: dto.unloading.forToday.map((feedItem) => ({
            ...feedItem,
            transportOrder: transportOrderDtoToDomain(feedItem.transportOrder)
        })),
        forTomorrow: dto.unloading.forTomorrow.map((feedItem) => ({
            ...feedItem,
            transportOrder: transportOrderDtoToDomain(feedItem.transportOrder)
        })),
        forYesterday: dto.unloading.forYesterday.map((feedItem) => ({
            ...feedItem,
            transportOrder: transportOrderDtoToDomain(feedItem.transportOrder)
        })),
        late: dto.unloading.late.map((feedItem) => ({
            ...feedItem,
            transportOrder: transportOrderDtoToDomain(feedItem.transportOrder)
        }))
    }});
