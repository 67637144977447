import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransportOrder } from '../../domain/transportOrder';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';
import { UpdateTransportOrderDto } from './dto/updateTransportOrderDto';
import { onSuccessfulTransportOrderChange } from './onSuccessfulTransportOrderChange';

export const useModifyTransportOrderRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const modifyTransportOrder = useMutation({
        mutationFn: (modifiedTransportOrder: TransportOrder) => modifyTransportOrderFunction(modifiedTransportOrder),
        onSuccess: (changedTransportOrder) => onSuccessfulTransportOrderChange(changedTransportOrder, queryClient),
    });

    const modifyTransportOrderFunction = async (modifiedTransportOrder: TransportOrder) => {
        const token = await getJwtToken();

        const updateTransportOrderDto: UpdateTransportOrderDto = {
            creator: modifiedTransportOrder.creator,
            contractor: modifiedTransportOrder.contractor && {
                id: modifiedTransportOrder.contractor.id,
                contactId: modifiedTransportOrder.contractor.contact?.id,
            },
            loading: modifiedTransportOrder.loading.map((loadingItem) => ({
                ...loadingItem,
                date: loadingItem.date.getTime(),
                endDate: loadingItem.endDate.getTime(),
            })),
            unloading: modifiedTransportOrder.unloading.map((unloadingItem) => ({
                ...unloadingItem,
                date: unloadingItem.date.getTime(),
                endDate: unloadingItem.endDate.getTime(),
            })),
            loadDetails: {
                items: modifiedTransportOrder.loadDetails.items.filter((item) => item.name || item.value),
                additionalInfo: modifiedTransportOrder.loadDetails.additionalInfo,
            },
            driver: modifiedTransportOrder.driver,
            vehicle: modifiedTransportOrder.vehicle,
            freight: modifiedTransportOrder.freight,
            additionalInfo: modifiedTransportOrder.additionalInfo,
            comment: modifiedTransportOrder.comment,
        };

        const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}/${modifiedTransportOrder.id}`, {
            method: 'PUT',
            body: JSON.stringify(updateTransportOrderDto),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const dto = await response.json() as GetTransportOrderDto;

        return toDomain(dto)
    };

    return {
        modifyTransportOrder: (modifiedTransportOrder: TransportOrder) => modifyTransportOrder.mutateAsync(modifiedTransportOrder),
    };
};