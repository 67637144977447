import { useSearchParams } from 'react-router-dom';
import { ContractorsFilters as ContractorsFiltersType } from '../../domain/contractorsFilters';

export const useContractorsFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get('query');
    const contractorsFilters: ContractorsFiltersType = {
        query: query ? {
            value: query,
        } : undefined,
    }

    const onFiltersChange = (newFilters?: ContractorsFiltersType) => {
        const query = newFilters?.query?.value ? `query=${newFilters?.query?.value}` : '';

        setSearchParams(query || '');
    }

    return {
        filters: contractorsFilters,
        onFiltersChange
    }
}
