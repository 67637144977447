import moment from 'moment';
import React, { useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { RefreshButton } from '../../components/RefreshButton';
import { TransportOrderFeed, TransportOrderFeedItem } from '../../domain/transportOrdersFeed';
import {
    useTransportOrdersFeedRepository,
} from '../../repository/transport-orders-feed/useTransportOrdersFeedRepository';
import { TransportOrderFeedTable } from './TransportOrderFeedTable';
import { TransportOrdersFeedLoadingSkeleton } from './TransportOrdersFeedLoadingSkeleton';

type TransportOrdersFeedsForGivenItemsProps = {
    loadingItems: Array<TransportOrderFeedItem>;
    unloadingItems: Array<TransportOrderFeedItem>;
    noLoadingItemsMessage: string;
    noUnloadingItemsMessage: string;
    dateIncluded: boolean;
}

const currentWeekday = moment().day();

const TransportOrdersFeedsForGivenItems = ({
    loadingItems,
    unloadingItems,
    noLoadingItemsMessage,
    noUnloadingItemsMessage,
    dateIncluded,
}: TransportOrdersFeedsForGivenItemsProps) =>
    <div className="row">
        <TransportOrderFeedTable
            className="pe-2 col-md-12 col-xl-6"
            label="Załadunki"
            addressLabel="Adres(y) załadunku"
            items={loadingItems}
            noItemsMessage={noLoadingItemsMessage}
            dateIncluded={dateIncluded}
            context={{ shipmentType: 'loading' }}
        />
        <TransportOrderFeedTable
            className="ps-2 col-md-12 col-xl-6"
            label="Rozładunki"
            addressLabel="Adres(y) rozładunku"
            items={unloadingItems}
            noItemsMessage={noUnloadingItemsMessage}
            dateIncluded={dateIncluded}
            context={{ shipmentType: 'unloading' }}
        />
    </div>;


export const TransportOrdersFeed = () => {
    const [key, setKey] = useState('forTodayTab');
    const {
        data: transportOrdersFeed,
        refetch: refetchFeedItems,
        isPending,
        isRefetching,
    } = useTransportOrdersFeedRepository();

    if (isPending || !transportOrdersFeed) {
        return <TransportOrdersFeedLoadingSkeleton />;
    }

    const feedSortCompareFunction = (a: TransportOrderFeedItem, b: TransportOrderFeedItem) => {
        if (a.type === b.type) {
            return 0;
        }
        return a.type === 'toLoad' ? 1 : 0;
    };

    const forYesterdayLabel = () => {
        if (currentWeekday === 0 || currentWeekday === 1) { // sunday or monday
            return 'Od piątku';
        }
        return 'Wczoraj'
    }

    const loadingFeed: TransportOrderFeed = {
        forToday: transportOrdersFeed.loading.forToday.sort(feedSortCompareFunction),
        forTomorrow: transportOrdersFeed.loading.forTomorrow.sort(feedSortCompareFunction),
        forYesterday: transportOrdersFeed.loading.forYesterday.sort(feedSortCompareFunction),
        late: transportOrdersFeed.loading.late,
    };

    const unloadingFeed: TransportOrderFeed = {
        forToday: transportOrdersFeed.unloading.forToday.sort(feedSortCompareFunction),
        forTomorrow: transportOrdersFeed.unloading.forTomorrow.sort(feedSortCompareFunction),
        forYesterday: transportOrdersFeed.unloading.forYesterday.sort(feedSortCompareFunction),
        late: transportOrdersFeed.unloading.late,
    };

    const lateLabel = `Zaległe (${transportOrdersFeed.loading.late.length + transportOrdersFeed.unloading.late.length})`;
    const todayLabel = `Dzisiaj (${transportOrdersFeed.loading.forToday.length + transportOrdersFeed.unloading.forToday.length})`;
    const yesterdayLabel = `${forYesterdayLabel()} (${transportOrdersFeed.loading.forYesterday.length + transportOrdersFeed.unloading.forYesterday.length})`;
    const tomorrowLabel = `Jutro (${transportOrdersFeed.loading.forTomorrow.length + transportOrdersFeed.unloading.forTomorrow.length})`;

    return (
        <Card>
            <Card.Header className="border-white d-flex justify-content-between">
                <Tabs activeKey={key} onSelect={(key) => setKey(key || '')}>
                    <Tab eventKey="lateTab" title={lateLabel} />
                    <Tab eventKey="forYesterdayTab" title={yesterdayLabel} />
                    <Tab eventKey="forTodayTab" title={todayLabel} />
                    <Tab eventKey="forTomorrowTab" title={tomorrowLabel} />
                </Tabs>
                <RefreshButton refreshFunction={refetchFeedItems} isRefreshing={isRefetching} />
            </Card.Header>
            <Card.Body className="position-relative">
                {key === 'lateTab' && <TransportOrdersFeedsForGivenItems
                    loadingItems={loadingFeed.late}
                    unloadingItems={unloadingFeed.late}
                    noLoadingItemsMessage="Brak opóźnionych załadunków"
                    noUnloadingItemsMessage="Brak opóźnionych rozładunków"
                    dateIncluded={true}
                />}
                {key === 'forYesterdayTab' && <TransportOrdersFeedsForGivenItems
                    loadingItems={loadingFeed.forYesterday}
                    unloadingItems={unloadingFeed.forYesterday}
                    noLoadingItemsMessage={`Brak zaplanowanych załadunków ${(currentWeekday === 0 || currentWeekday === 1) ? 'od piątku' : 'na wczoraj'}`}
                    noUnloadingItemsMessage={`Brak zaplanowanych rozładunków ${(currentWeekday === 0 || currentWeekday === 1) ? 'od piątku' : 'na wczoraj'}`}
                    dateIncluded={true}
                />}
                {key === 'forTodayTab' && <TransportOrdersFeedsForGivenItems
                    loadingItems={loadingFeed.forToday}
                    unloadingItems={unloadingFeed.forToday}
                    noLoadingItemsMessage="Brak zaplanowanych załadunków na dzisiaj"
                    noUnloadingItemsMessage="Brak zaplanowanych rozładunków na dzisiaj"
                    dateIncluded={false}
                />}
                {key === 'forTomorrowTab' && <TransportOrdersFeedsForGivenItems
                    loadingItems={loadingFeed.forTomorrow}
                    unloadingItems={unloadingFeed.forTomorrow}
                    noLoadingItemsMessage="Brak zaplanowanych załadunków na jutro"
                    noUnloadingItemsMessage="Brak zaplanowanych rozładunków na jutro"
                    dateIncluded={false}
                />}
            </Card.Body>
        </Card>
    );
};