import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';

import { ContractorsFilters as ContractorsFiltersType } from '../../domain/contractorsFilters';

type ContractorsFiltersProps = {
    filters: ContractorsFiltersType;
    onFiltersChange: (filters: ContractorsFiltersType | undefined) => void;
    className?: string;
}

export const ContractorsFilters = ({ filters, onFiltersChange, className }: ContractorsFiltersProps) => {
    const [filtersState, setFiltersState] = useState<ContractorsFiltersType>({});

    const searchQuery = filtersState.query?.value || '';

    useEffect(() => {
        setFiltersState(filters);
    }, [filters]);

    const handleQueryChange = (query: string) => {
        setFiltersState((prevState) => ({
            ...prevState,
            query: { value: query },
        }));
    };

    const handleClear = () => {
        onFiltersChange(undefined);
    };

    const handleSearchSubmit = () => {
        onFiltersChange(filtersState);
    };

    return <div className={`d-flex flex-row align-items-end ${className}`}>
        <Form className="d-flex flex-row align-items-end" onSubmit={(e) => {
            e.preventDefault();
            handleSearchSubmit();
        }}>
            <div className="me-3">
                <h6>Wyszukaj po tekście</h6>
                <FormControl
                    name="query"
                    className="search-input"
                    type="text"
                    placeholder="Wyszukaj po tekście"
                    onChange={(e) => handleQueryChange(e.target.value)}
                    autoComplete="on"
                    value={searchQuery} />
            </div>
            <Button className="me-2" variant="primary" onClick={handleSearchSubmit} type="submit">Szukaj</Button>
            <Button variant="outline-primary" onClick={handleClear}>Wyczyść</Button>
        </Form>
    </div>;
};