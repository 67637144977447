import { Button } from 'react-bootstrap';
import { Copy, Trash } from 'react-bootstrap-icons';
import { ActionItem } from '../../../components/ActionItem';
import { FormElement } from '../../../components/FormElement';
import { TextareaFormGroup } from '../../../components/TextareaFormGroup';
import { TextFormGroup } from '../../../components/TextFormGroup';
import { TransportOrder } from '../../../domain/transportOrder';

type LoadDetailsProps = {
    loadDetails: TransportOrder['loadDetails'];
    setLoadDetails: (newLoadDetails: TransportOrder['loadDetails']) => void;
}

const EMPTY_LOAD_DETAILS: TransportOrder['loadDetails'] = {
    items: [],
    additionalInfo: '',
}

export const LoadDetails = ({ loadDetails = EMPTY_LOAD_DETAILS, setLoadDetails }: LoadDetailsProps) => {
    const handleItemNameChange = (index: number, changedName: string) => {
        setLoadDetails({
            items: loadDetails.items.map((item, itemIndex) => {
                if (itemIndex === index) {
                    return {
                        ...item,
                        name: changedName,
                    };
                }
                return item;
            }),
            additionalInfo: loadDetails.additionalInfo,
        });
    };

    const handleItemValueChange = (index: number, changedValue: string) => {
        setLoadDetails({
            items: loadDetails.items.map((item, itemIndex) => {
                if (itemIndex === index) {
                    return {
                        ...item,
                        value: changedValue,
                    };
                }
                return item;
            }),
            additionalInfo: loadDetails.additionalInfo,
        });
    };

    const handleAdditionalInfoChange = (newAdditionalInfo: string) => {
        setLoadDetails({ items: loadDetails.items, additionalInfo: newAdditionalInfo })
    }

    const handleAddItemClick = () => {
        setLoadDetails({
            items: [...loadDetails.items, { name: '', value: '' }],
            additionalInfo: loadDetails.additionalInfo
        });
    };

    const handleItemCloneClick = (cloneItemIndex: number) => {
        const newItem = loadDetails.items[cloneItemIndex];

        setLoadDetails({
            items: [
                ...loadDetails.items.slice(0, cloneItemIndex),
                newItem,
                ...loadDetails.items.slice(cloneItemIndex),
            ],
            additionalInfo: loadDetails.additionalInfo
        });
    };

    const handleItemRemoveClick = (removeItemIndex: number) => {
        setLoadDetails({
            items: loadDetails.items.filter((_, index) => index !== removeItemIndex),
            additionalInfo: loadDetails.additionalInfo,
        });
    };

    return (
        <FormElement headingText="Informacje o ładunku">
            {loadDetails.items.length > 0 && <ul className="list-unstyled mb-0">
                {loadDetails.items.map(({ name, value }, index) => (
                        <li className="mb-2 d-flex flex-row">
                            <TextFormGroup
                                className="w-25 pe-2"
                                label="Nazwa"
                                value={name}
                                onChange={(value) => handleItemNameChange(index, value)}
                            />
                            <TextFormGroup
                                className="w-75"
                                label="Wartość"
                                value={value}
                                onChange={(value) => handleItemValueChange(index, value)}
                            />
                            <ActionItem onClick={() => handleItemCloneClick(index)} className="ps-2">
                                <Copy />
                            </ActionItem>
                            <ActionItem onClick={() => handleItemRemoveClick(index)} className="ps-2">
                                <Trash />
                            </ActionItem>
                        </li>
                    ),
                )}
            </ul>}
            {loadDetails.items.length === 0 && (
                <p className="fst-italic text-center">
                    Brak informacji o ładunku.
                </p>
            )}
            <div className="d-flex justify-content-center">
                <Button variant="link" onClick={handleAddItemClick} className="text-decoration-none">
                    + dodaj
                </Button>
            </div>
            <TextareaFormGroup
                className="w-50"
                rows={8}
                label="Dodatkowe informacje o ładunku"
                value={loadDetails.additionalInfo}
                onChange={handleAdditionalInfoChange}
            />

        </FormElement>
    );
};