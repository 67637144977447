import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransportOrderFeedItem, TransportOrderFeedResponse } from '../../domain/transportOrdersFeed';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';
import { updateChangedTransportOrder } from './onSuccessfulTransportOrderChange';

type ChangeTransportOrderUnloadingCompletedMutationType = {
    transportOrderId: string,
    unloadingItem: {
        index: number,
        completed: boolean
    };
}

export const useChangeTransportOrderUnloadingCompletedRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changeTransportOrderUnloadingCompleted = useMutation({
        mutationFn: (variable: ChangeTransportOrderUnloadingCompletedMutationType) =>
            changeTransportOrderUnloadingCompletedFunction(variable.transportOrderId, variable.unloadingItem),
        onMutate: async (data) => {
            const transportOrderId = data.transportOrderId;
            const updateFeedItem = (feedItem: TransportOrderFeedItem) => {
                if (feedItem.id === transportOrderId) {
                    return {
                        ...feedItem,
                        elements: feedItem.elements.map((element, index) => {
                            if (index === data.unloadingItem.index) {
                                return {
                                    ...element,
                                    completed: data.unloadingItem.completed,
                                }
                            }
                            return element;
                        })
                    }
                }
                return feedItem;
            }


            await queryClient.setQueryData(['transportOrders', 'feed'], (feed: TransportOrderFeedResponse): TransportOrderFeedResponse => ({
                    ...feed,
                    unloading: {
                        forToday: feed.unloading.forToday.map(updateFeedItem),
                        forYesterday: feed.unloading.forYesterday.map(updateFeedItem),
                        forTomorrow: feed.unloading.forTomorrow.map(updateFeedItem),
                        late: feed.unloading.late.map(updateFeedItem),
                    },
                }
            ));
        },
        onSuccess: async (changedTransportOrder) => {
            updateChangedTransportOrder(changedTransportOrder, queryClient)
        },
    });

    const changeTransportOrderUnloadingCompletedFunction = async (transportOrderId: string, unloadingItem: {
        index: number,
        completed: boolean
    }) => {
        const token = await getJwtToken();

        const result = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}/${transportOrderId}`, {
            method: 'PATCH',
            body: JSON.stringify({
                unloadingCompleted: {
                    index: unloadingItem.index,
                    completed: unloadingItem.completed,
                },
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const dto = await result.json() as GetTransportOrderDto;

        return toDomain(dto);
    };

    return {
        changeTransportOrderUnloadingCompleted: (variable: ChangeTransportOrderUnloadingCompletedMutationType) => changeTransportOrderUnloadingCompleted.mutateAsync(variable),
    };
};