import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { EditableComponent } from '../../../components/EditableComponent';
import { PageTitle } from '../../../components/PageTitle';
import { TransportOrder } from '../../../domain/transportOrder';
import { useChangeTransportOrderOrderId } from '../../../repository/transport-orders/useChangeTransportOrderIdRepository';

type OrderIdProps = {
    transportOrder: TransportOrder;
}
export const OrderId = ({ transportOrder }: OrderIdProps) => {
    const { changeTransportOrderOrderId } = useChangeTransportOrderOrderId();

    const [editMode, setEditMode] = useState(false);
    const [modifiedOrderId, setModifiedOrderId] = useState(transportOrder.orderId);

    useEffect(() => {
        setModifiedOrderId(transportOrder.orderId);
    }, [transportOrder.orderId]);

    const handleEditClick = () => setEditMode(true);

    const handleOrderIdChange = (newOrderId: string) => {
        setModifiedOrderId(newOrderId);
    }

    const handleOrderIdSubmit = async () => {
        await changeTransportOrderOrderId({ transportOrderId: transportOrder.id, newOrderId: modifiedOrderId });
        toast.success('Edycja numeru zlecenia powiodła się');

        setEditMode(false);
    }

    if (transportOrder.status === 'DRAFT') {
        return <PageTitle>
            Wersja robocza
        </PageTitle>
    }

    if (editMode) {
        return <div className="d-flex flex-row w-">
            <PageTitle className="d-inline-block">Zlecenie nr</PageTitle>
            <Form onSubmit={(e) => {
                e.preventDefault();
                handleOrderIdSubmit();
            }}>
                <Form.Control
                    className="ms-2 d-inline-block width-120"
                    type="text"
                    value={modifiedOrderId}
                    onChange={(e) => handleOrderIdChange(e.target.value)} />
                <Button
                    className="px-1"
                    variant="link"
                    type="submit"
                    // onClick={(e) => handleOrderIdSubmit()}
                >
                    Zapisz
                </Button>
            </Form>

        </div>;
    }

    return (
        <EditableComponent className="me-4" onEditClick={handleEditClick}>
            <PageTitle className="d-inline">
                Zlecenie nr {transportOrder.orderId}
            </PageTitle>
        </EditableComponent>
    );
};