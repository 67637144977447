import React from 'react';
import { Link } from 'react-router-dom';
import { TransportOrder } from '../../domain/transportOrder';

const dateToString = (date: Date) =>
    date.toLocaleDateString('pl-PL', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    });

export const TransportOrderPopoverBody = ({ transportOrder }: { transportOrder: TransportOrder }) => {
    return (<>
        <Link to={`/transport-orders/${transportOrder.id}`}>{transportOrder.orderId}</Link><br />
        <span
            className="d-inline-block text-black-50 mb-2">Utworzono {dateToString(new Date(transportOrder.creationDate))}</span>
        <div className="d-flex flex-row justify-content-between align-items-start mb-2">
            <TransportOrderPopoverBodyLoading loading={transportOrder.loading} />
            <TransportOrderPopoverBodyUnloading unloading={transportOrder.unloading} />
        </div>
        <LoadDetailsPopoverBody loadDetails={transportOrder.loadDetails} />
        <DriverPopoverBody driver={transportOrder.driver} />
        <VehiclePopoverBody vehicle={transportOrder.vehicle} />
        <AdditionalInfoPopoverBody additionalInfo={transportOrder.additionalInfo} />
        <CommentPopoverBody comment={transportOrder.comment} />
    </>);
};

const TransportOrderPopoverBodyLoading = ({ loading }: { loading: TransportOrder['loading'] }) => {
    if (loading.length === 1) {
        return (<div className="mb-2 flex-grow-1">
            <h6 className="mb-0">Załadunek</h6>
            <div>{loading[0].address}</div>
            <div>
                <b>Data</b>: {buildDate(loading[0].date, loading[0].endDate)}{loading[0].time && <>, {loading[0].time}</>}
            </div>
            <div>
                <b>Numer załadunku</b>: {loading[0].loadingNumber ||
                <span className="text-black-50 fst-italic">brak</span>}
            </div>
            {loading[0].additionalInfo && (<div>
                <b>Uwagi</b>: {loading[0].additionalInfo}
            </div>)}
        </div>);
    }
    return <ul className="list-unstyled mb-2 flex-grow-1">
        {loading.map((loadingItem, index) => <li className="mb-2" key={index}>
            <h6 className="mb-0">Załadunek {index + 1}</h6>
            <div>{loadingItem.address}</div>
            <div>
                <b>Data</b>: {buildDate(loadingItem.date, loadingItem.endDate)}{loadingItem.time && <>, {loadingItem.time}</>}
            </div>
            <div>
                <b>Numer załadunku</b>: {loadingItem.loadingNumber ||
                <span className="text-black-50 fst-italic">brak</span>}
            </div>
            {loadingItem.additionalInfo && (<div>
                <b>Uwagi</b>: {loadingItem.additionalInfo}
            </div>)}
        </li>)}
    </ul>;
};

const TransportOrderPopoverBodyUnloading = ({ unloading }: { unloading: TransportOrder['unloading'] }) => {
    if (unloading.length === 1) {
        return (<div className="mb-2 flex-grow-1">
            <h6 className="mb-0">Rozładunek</h6>
            <div>{unloading[0].address}</div>
            <div>
                <b>Data</b>: {buildDate(unloading[0].date, unloading[0].endDate)}{unloading[0].time && <>, {unloading[0].time}</>}
            </div>
            <div>
                <b>Numer rozładunku</b>: {unloading[0].unloadingNumber ||
                <span className="text-black-50 fst-italic">brak</span>}
            </div>
            {unloading[0].additionalInfo && (<div>
                <b>Uwagi</b>: {unloading[0].additionalInfo}
            </div>)}
        </div>);
    }
    return <ul className="list-unstyled mb-2 flex-grow-1">
        {unloading.map((unloadingItem, index) => <li className="mb-2" key={index}>
            <h6 className="mb-0">Rozładunek {index + 1}</h6>
            <div>{unloadingItem.address}</div>
            <div>
                <b>Data</b>: {buildDate(unloadingItem.date, unloadingItem.endDate)}{unloadingItem.time && <>, {unloadingItem.time}</>}
            </div>
            <div>
                <b>Numer rozładunku</b>: {unloadingItem.unloadingNumber ||
                <span className="text-black-50 fst-italic">brak</span>}
            </div>
            {unloadingItem.additionalInfo && (<div>
                <b>Uwagi</b>: {unloadingItem.additionalInfo}
            </div>)}
        </li>)}
    </ul>;
};

const LoadDetailsPopoverBody = ({ loadDetails }: { loadDetails: TransportOrder['loadDetails'] }) => {
    if (loadDetails.items.length === 0 && !loadDetails.additionalInfo) {
        return <span className="fst-italic text-black-50">Brak informacji o ładunku</span>;
    }

    return (<div>
        <h6 className="mb-0">Ładunek</h6>
        {loadDetails.items.length > 0 && <ul className="list-unstyled">
            {loadDetails.items.map((loadDetailsItem) => (<li>
                {loadDetailsItem.name}: {loadDetailsItem.value}
            </li>))}
        </ul>}
        <p className="text-pre-line">{loadDetails.additionalInfo}</p>
    </div>);
};

const DriverPopoverBody = ({ driver }: { driver: TransportOrder['driver'] }) => {
    if (!driver.name && !driver.phoneNumber && !driver.identityCardNumber) {
        return null;
    }

    const driverDetails = [driver.name, driver.phoneNumber, driver.identityCardNumber].filter(Boolean).join(', ');

    return <div className="mb-2">
        <h6 className="mb-0">Kierowca</h6>
        {driverDetails}
    </div>;
};

const VehiclePopoverBody = ({ vehicle }: { vehicle: TransportOrder['vehicle'] }) => {
    if (!vehicle.carLicensePlate && !vehicle.trailerLicensePlate) {
        return null;
    }

    return <div className="mb-2 d-flex flex-row">
        {vehicle.carLicensePlate && <div className="flex-grow-1 mb-2">
            <h6 className="mb-0">Ciągnik</h6>{vehicle.carLicensePlate}
        </div>}
        {vehicle.trailerLicensePlate && <div className="flex-grow-1 mb-2">
            <h6 className="mb-0">Naczepa</h6>{vehicle.trailerLicensePlate}
        </div>}

    </div>;
};

const AdditionalInfoPopoverBody = ({ additionalInfo }: { additionalInfo: string }) => {
    if (!additionalInfo) {
        return null;
    }

    return <div className="mb-2">
        <h6 className="mb-0">Uwagi</h6>
        {additionalInfo}
    </div>;
};

const CommentPopoverBody = ({ comment }: { comment: string }) => {
    if (!comment) {
        return null;
    }

    return <div className="mb-2">
        <h6 className="mb-0">Komentarz</h6>
        {comment}
    </div>;
};

const buildDate = (date: Date, dateEnd: Date) => {
    if (date.getDate() === dateEnd.getDate()) {
        return dateToString(date);
    }

    return (<>{dateToString(date)} - {dateToString(dateEnd)}</>);
};