import moment from 'moment';
import { Link } from 'react-router-dom';

import {
    useTransportOrdersByCreatorAndMonthRepository,
} from '../../repository/transport-orders/useTransportOrdersByCreatorAndMonthRepository';
import { useLoggedInUser } from '../users/LoggedInUserProvider';

const now = moment();
const currentMonth = now.month() + 1;
const currentYear = now.year();

export const SpeditorProfit = ({ className = '' }: { className?: string }) => {
    const { loggedInUser } = useLoggedInUser();

    const { data } = useTransportOrdersByCreatorAndMonthRepository(
        loggedInUser.sub,
        currentMonth,
        currentYear,
    );

    if (!data || data.length === 0) {
        return null;
    }

    const profits = data
        .filter(({ status } ) => status !== 'DRAFT' && status !== 'STORNO')
        .map(({ freight }) => Number(freight.clientValue) - Number(freight.value))
        .filter((profitValue) => !isNaN(profitValue));

    if (profits.length === 0) {
        return <div>Nie można obliczyć marży. W {currentMonth}-{currentYear} nie ma zleceń z podaną ceną klienta</div>;
    }

    const totalProfit = profits.reduce((acc, curr) => acc + curr, 0);
    const displayedMonthNumber = currentMonth < 10 ? `0${currentMonth}` : currentMonth;

    return <h6 className={`text-black-50 fst-italic ${className}`}>
        Marża <Link
            to={`/transport-orders?orderYear=${currentYear}&orderMonth=${currentMonth}&creatorId=${loggedInUser.sub}&showProfit=on`}>
            {displayedMonthNumber}-{currentYear}
        </Link> to <span className={totalProfit > 0 ? 'text-success' : 'text-danger'}>{totalProfit} EUR</span>
    </h6>;
};