import moment from 'moment';
import { CreateTransportOrderDto } from '../../../repository/transport-orders/dto/createTransportOrderDto';

export type ErrorTypes =
    'LAST_LOADING_AFTER_LAST_UNLOADING'
    | 'EMPTY_LOAD_DETAILS'
    | 'LOADING_END_DATE_BEFORE_START_DATE'
    | 'UNLOADING_END_DATE_BEFORE_START_DATE';

export type WarningTypes =
    'LOADING_DATE_IN_PAST'
    | 'UNLOADING_DATE_IN_PAST'
    | 'UNLOADING_DATE_TOO_MANY_DAYS_AFTER_LOADING'
    | 'NO_LOAD_DETAILS_PROVIDED'
    | 'LOADING_ADDRESS_EMPTY'
    | 'UNLOADING_ADDRESS_EMPTY'
    | 'NO_CLIENT_VALUE'

export const newTransportOrderValidation = (transportOrder: CreateTransportOrderDto): {
    errors: Array<{ type: ErrorTypes }>,
    warnings: Array<{ type: WarningTypes }>,
} => {
    return {
        errors: checkForErrors(transportOrder),
        warnings: checkForWarnings(transportOrder),
    };
};

const checkForErrors = (transportOrder: CreateTransportOrderDto): Array<{ type: ErrorTypes }> => {
    const errors: Array<{ type: ErrorTypes }> = [];


    // 1. last loading sorted by date is after last unloading sorted by date
    if (transportOrder.loading.length > 0 && transportOrder.unloading.length > 0) {
        const lastLoadingDate = transportOrder.loading.sort((a, b) => b.date - a.date)[0].date;
        const lastUnloadingDate = transportOrder.unloading.sort((a, b) => b.date - a.date)[0].date;
        if (lastLoadingDate > lastUnloadingDate) {
            errors.push({ type: 'LAST_LOADING_AFTER_LAST_UNLOADING' });
        }
    }

    // 2. at least one loadDetails item has empty name or value
    if (transportOrder.loadDetails.items.some(detail => detail.name === '' || detail.value === '')) {
        errors.push({ type: 'EMPTY_LOAD_DETAILS' });
    }

    // 3. at least one loading has endDate smaller than date
    if (transportOrder.loading.some(item => item.endDate < item.date)) {
        errors.push({ type: 'LOADING_END_DATE_BEFORE_START_DATE' });
    }

    // 4. at least one unloading has endDate smaller than date
    if (transportOrder.unloading.some(item => item.endDate < item.date)) {
        errors.push({ type: 'UNLOADING_END_DATE_BEFORE_START_DATE' });
    }

    return errors;
};

const isDateInPast = (date: Date | number) => {
    const momentDate = moment(date).startOf('day');
    const today = moment().startOf('day');
    return momentDate.isBefore(today);
};


const checkForWarnings = (transportOrder: CreateTransportOrderDto): Array<{ type: WarningTypes }> => {
    const warnings: Array<{ type: WarningTypes }> = [];

    // 1. at least one loading item has date in past
    if (transportOrder.loading.some(item => isDateInPast(item.date))) {
        warnings.push({ type: 'LOADING_DATE_IN_PAST' });
    }

    // 2. at least one unloading item has date in past
    if (transportOrder.unloading.some(item => isDateInPast(item.date))) {
        warnings.push({ type: 'UNLOADING_DATE_IN_PAST' });
    }

    // 3. first unloading sorted by date is bigger than last loading sorted by date by at least 7 days
    if (transportOrder.loading.length > 0 && transportOrder.unloading.length > 0) {
        const lastLoadingDate = transportOrder.loading.sort((a, b) => b.endDate - a.endDate)[0].endDate;
        const firstUnloadingDate = transportOrder.unloading.sort((a, b) => a.endDate - b.endDate)[0].date;
        const sevenDays = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds
        if (firstUnloadingDate - lastLoadingDate >= sevenDays) {
            warnings.push({ type: 'UNLOADING_DATE_TOO_MANY_DAYS_AFTER_LOADING' });
        }
    }

    // 4. no loadDetails provided
    if (transportOrder.loadDetails.items.length === 0 && transportOrder.loadDetails.additionalInfo === '') {
        warnings.push({ type: 'NO_LOAD_DETAILS_PROVIDED' });
    }

    // 5. loading address is empty
    if (transportOrder.loading.some(item => item.address === '')) {
        warnings.push({ type: 'LOADING_ADDRESS_EMPTY' });
    }

    // 6. unloading address is empty
    if (transportOrder.unloading.some(item => item.address === '')) {
        warnings.push({ type: 'UNLOADING_ADDRESS_EMPTY' });
    }

    // 7. no client value under freight
    if (!transportOrder.freight.clientValue) {
        warnings.push({ type: 'NO_CLIENT_VALUE' });
    }

    return warnings;
};