import { useState } from 'react';
import { TransportOrder } from '../../../domain/transportOrder';
import { UserAccount } from '../../../domain/userAccount';
import { CreateTransportOrderDto } from '../../../repository/transport-orders/dto/createTransportOrderDto';

import { MutableValue, OfMutable } from '../../../utils/formTypes';
import { useLoggedInUser } from '../../users/LoggedInUserProvider';

type NewTransportOrderForm = {
    creator: MutableValue<TransportOrder['creator']>;
    contractor: MutableValue<TransportOrder['contractor']>;
    driver: MutableValue<TransportOrder['driver']>;
    vehicle: MutableValue<TransportOrder['vehicle']>;
    loading: MutableValue<TransportOrder['loading']>;
    unloading: MutableValue<TransportOrder['unloading']>;
    loadDetails: MutableValue<TransportOrder['loadDetails']>;
    freight: MutableValue<TransportOrder['freight']>;
    additionalInfo: MutableValue<TransportOrder['additionalInfo']>;
    comment: MutableValue<TransportOrder['comment']>;
}

const emptyContractor: TransportOrder['contractor'] = undefined;
const emptyDriver: TransportOrder['driver'] = { name: '', identityCardNumber: '', phoneNumber: '' };
const emptyVehicle: TransportOrder['vehicle'] = { carLicensePlate: '', trailerLicensePlate: '' };
const emptyLoading: TransportOrder['loading'] = [{
    address: '',
    date: new Date(),
    endDate: new Date(),
    time: '',
    loadingNumber: '',
    additionalInfo: '',
    completed: false,
}];
const emptyUnloading: TransportOrder['unloading'] = [{
    address: '',
    date: new Date(),
    endDate: new Date(),
    time: '',
    unloadingNumber: '',
    additionalInfo: '',
    completed: false,
}];
const emptyLoadDetails: TransportOrder['loadDetails'] = {
    items: [{ name: '', value: '' }],
    additionalInfo: '',
};
const emptyFreight: TransportOrder['freight'] = { value: '0', currency: 'EUR', vatRate: 23, paymentDays: 45, clientValue: undefined };
const emptyAdditionalInfo: TransportOrder['additionalInfo'] = '';
const emptyComment: TransportOrder['comment'] = '';

const creatorFromLoggedInUser = (user: UserAccount): TransportOrder['creator'] => ({
    id: user.sub,
    name: `${user.firstName} ${user.lastname}`,
    email: user.email,
    phoneNumber: user.phoneNumber,
})

export const useNewTransportOrderForm = (initialTransportOrder?: Partial<TransportOrder>): {
    data: NewTransportOrderForm;
    toCreationDto: (transportOrderForm: NewTransportOrderForm) => CreateTransportOrderDto;
} => {
    const { loggedInUser } = useLoggedInUser();
    const [creator, setCreator] = useState(creatorFromLoggedInUser(loggedInUser));
    const [contractor, setContractor] = useState(initialTransportOrder?.contractor || emptyContractor);
    const [driver, setDriver] = useState(initialTransportOrder?.driver || emptyDriver);
    const [vehicle, setVehicle] = useState(initialTransportOrder?.vehicle || emptyVehicle);
    const [loading, setLoading] = useState(initialTransportOrder?.loading || emptyLoading);
    const [unloading, setUnloading] = useState(initialTransportOrder?.unloading || emptyUnloading);
    const [loadDetails, setLoadDetails] = useState(initialTransportOrder?.loadDetails || emptyLoadDetails);
    const [freight, setFreight] = useState(initialTransportOrder?.freight || emptyFreight);
    const [additionalInfo, setAdditionalInfo] = useState(initialTransportOrder?.additionalInfo || emptyAdditionalInfo);
    const [comment, setComment] = useState(initialTransportOrder?.comment || emptyComment);

    const toCreationDto = (transportOrderForm: NewTransportOrderForm): CreateTransportOrderDto => {
        const {
            creator,
            contractor,
            driver,
            vehicle,
            loading,
            unloading,
            loadDetails,
            freight,
            additionalInfo,
            comment,
        } = transportOrderForm;

        return {
            creator: creator.value,
            contractor: contractor.value && {
                id: contractor.value.id || '',
                contactId: contractor.value.contact?.id || '',
            },
            driver: driver.value,
            vehicle: vehicle.value,
            loading: loading.value.map((loadingItem) => ({
                ...loadingItem,
                date: loadingItem.date.getTime(),
                endDate: loadingItem.endDate.getTime()
            })),
            unloading: unloading.value.map((unloadingItem) => ({
                ...unloadingItem,
                date: unloadingItem.date.getTime(),
                endDate: unloadingItem.endDate.getTime()
            })),
            loadDetails: {
                items: loadDetails.value.items.filter((item) => item.name || item.value),
                additionalInfo: loadDetails.value.additionalInfo,
            },
            freight: freight.value,
            additionalInfo: additionalInfo.value,
            comment: comment.value,
        };
    };

    return {
        data: {
            creator: OfMutable(creator, setCreator),
            contractor: OfMutable(contractor, setContractor),
            driver: OfMutable(driver, setDriver),
            vehicle: OfMutable(vehicle, setVehicle),
            loading: OfMutable(loading, setLoading),
            unloading: OfMutable(unloading, setUnloading),
            loadDetails: OfMutable(loadDetails, setLoadDetails),
            freight: OfMutable(freight, setFreight),
            additionalInfo: OfMutable(additionalInfo, setAdditionalInfo),
            comment: OfMutable(comment, setComment),
        },
        toCreationDto,
    };
};