import { Font, jsPDF, TextOptionsLight } from 'jspdf';
import { CompanyDetails } from '../../../../domain/companyDetails';
import { CompanyLogo } from '../../../../domain/companyLogo';
import { TransportOrderConfig } from '../../../../domain/transportOrderConfig';
import { TransportOrderPolicy } from '../../../../domain/transportOrderPolicy';

import { TransportOrder } from '../../../../domain/transportOrder';
import { fontBold, fontBoldItalic, fontItalic, fontNormal } from './roboto';

type HeadingText = {
    heading: string;
    text: string;
}

const MAX_LOGO_HEIGHT = 30;

export class TransportOrderPdfCreator {
    readonly document;
    private readonly documentWidth;
    private readonly documentHeight;
    private readonly marginLeft: number;
    private readonly marginRight: number;
    private readonly marginTop: number;
    private readonly marginBottom: number;
    private readonly defaultFont: Font;
    private readonly defaultFontSize: number; // 16
    private readonly defaultTextColor: string;
    private currentHeight: number;

    constructor(
        private readonly transportOrder: TransportOrder,
        private readonly company: CompanyDetails,
        private readonly transportOrderConfig: TransportOrderConfig,
        private readonly policy: TransportOrderPolicy,
        private readonly companyLogo: CompanyLogo,
    ) {
        this.document = new jsPDF();

        const pageSize = this.document.internal.pageSize;
        this.documentWidth = pageSize.getWidth();
        this.documentHeight = pageSize.getHeight();

        this.marginLeft = 12;
        this.marginRight = 12;
        this.marginTop = 10;
        this.marginBottom = 10;

        this.initFont();

        this.defaultFont = this.document.getFont();
        this.defaultFontSize = this.document.getFontSize();
        this.defaultTextColor = this.document.getTextColor();

        this.currentHeight = this.marginTop;
    }

    create = () => {
        // this.layoutLines();
        this.logoAndDocumentNumberSection();
        this.contactInfo();
        this.loading();
        this.unloading();
        this.loadDetails();
        this.additionalInfo();
        this.payment();
        this.signatureSpace();
        this.transportOrder.status === 'STORNO' && this.storno();
        this.owu();
    };

    download = () => {
        this.document.save(this.getFilename());
    };

    getFilename = () => {
        const documentName = this.transportOrder.orderId || 'zlecenie-transportowe';

        return `${documentName.replaceAll('/', '-')}.pdf`;
    };

    initFont = () => {
        const fontName = 'Roboto';

        this.document.addFileToVFS(`${fontName}-normal.ttf`, fontNormal);
        this.document.addFileToVFS(`${fontName}-bold.ttf`, fontBold);
        this.document.addFileToVFS(`${fontName}-italic.ttf`, fontItalic);
        this.document.addFileToVFS(`${fontName}-bold-italic.ttf`, fontBoldItalic); // additional 14kb in pdf size

        this.document.addFont(`${fontName}-normal.ttf`, fontName, 'normal');
        this.document.addFont(`${fontName}-bold.ttf`, fontName, 'bold');
        this.document.addFont(`${fontName}-italic.ttf`, fontName, 'italic');
        this.document.addFont(`${fontName}-bold-italic.ttf`, fontName, 'bolditalic');

        this.document.setFont(fontName);
    };

    private owu = () => {
        const x = this.marginLeft;
        const availableSpace = this.documentWidth - x - this.marginRight;

        this.document.addPage();
        this.currentHeight = this.marginTop;

        this.owuHeader();

        this.policy.forEach((policyItem) => {
            this.textHeading(policyItem.name, x, availableSpace);
            policyItem.value.split('\n')
                .forEach((item) => {
                    this.textNormal(item, x, availableSpace);
                });
            this.currentHeight += 2;
        });
    };

    private owuHeader = () => {
        const x = this.marginLeft;
        const availableSpace = this.documentWidth - x - this.marginRight;

        this.document.setFontSize(16);
        this.document.setFont(this.defaultFont.fontName, this.defaultFont.fontStyle, 'bold');
        this.text('Ogólne warunki zlecenia', x, availableSpace);
        this.setDefaultFont();

        this.currentHeight += 3;
    };

    private storno = () => {
        const pagesWithStorno = this.document.getNumberOfPages();
        const text = 'STORNO';

        this.document.setFontSize(90);
        const { w } = this.document.getTextDimensions('STORNO');

        const x = (this.documentWidth / 2) - (w * Math.sqrt(2) / 4);
        const y = (this.documentHeight / 2);

        this.document.setTextColor(255, 0, 0);

        for (let i = 0; i < pagesWithStorno; i++) {
            this.document.setPage(i + 1);
            this.document.text(text, x, y, undefined, 45);
        }

        this.setDefaultFont();
    };

    private signatureSpace = () => {
        this.addPageIfNoSpaceAvailable(30);

        const text = 'Oświadczam, iż akceptuję warunki przedstawione na pozostałych stronach zlecenia.';
        const contractorLabel = 'Zleceniobiorca';
        const creatorLabel = 'Zleceniodawca';

        const availableSpace = this.documentWidth - this.marginLeft - this.marginRight;
        const textX = this.documentWidth / 2;

        this.currentHeight += 10;

        this.document.setFont(this.defaultFont.fontName, 'italic');
        this.document.setFontSize(9);
        const { h: textHeight } = this.document.getTextDimensions(text);
        this.document.text(text, textX, this.currentHeight + textHeight, { align: 'center', maxWidth: availableSpace });
        this.setDefaultFont();

        this.currentHeight += textHeight + 1.5;

        this.document.line(this.marginLeft, this.currentHeight, this.documentWidth - this.marginRight, this.currentHeight);
        this.document.line(this.marginLeft, this.currentHeight, this.marginLeft, this.currentHeight + 20);
        this.document.line(this.documentWidth - this.marginRight, this.currentHeight, this.documentWidth - this.marginRight, this.currentHeight + 20);
        this.document.line(this.marginLeft, this.currentHeight + 20, this.documentWidth - this.marginRight, this.currentHeight + 20);

        const contractorLabelX = this.documentWidth / 4;
        const creatorLabelX = 3 * (this.documentWidth / 4);

        this.document.setFontSize(12);
        const { h: lineHeight } = this.document.getTextDimensions(contractorLabel);
        this.document.text(contractorLabel, contractorLabelX, this.currentHeight + lineHeight, {
            align: 'center',
            maxWidth: this.documentWidth / 2,
        });
        this.document.text(creatorLabel, creatorLabelX, this.currentHeight + lineHeight, {
            align: 'center',
            maxWidth: this.documentWidth / 2,
        });
        this.setDefaultFont();
    };

    private payment = () => {
        this.addPageIfNoSpaceAvailable(25 + 30); // 25 height of payment section and 30 of signature - to not have just signature alone

        const { value, vatRate, currency, paymentDays } = this.transportOrder.freight;

        const x = this.marginLeft;
        const availableSpace = (this.documentWidth - x) - this.marginRight;

        this.currentHeight += 5;
        this.textHeading('PŁATNOŚĆ', x, availableSpace);
        this.textNormalWithBoldPrefix('Netto', `${value} ${currency} + ${vatRate}% VAT`, x, availableSpace);
        this.textNormalWithBoldPrefix('Termin płatności', `${paymentDays} ${this.transportOrderConfig.paymentDaysAnnotation}`, x, availableSpace);
        this.paymentAdditionalInfo();
    };

    private paymentAdditionalInfo = () => {
        const x = this.marginLeft;
        const availableSpace = (this.documentWidth - x) - this.marginRight;

        this.document.setFont(this.defaultFont.fontName, 'italic');
        this.document.setFontSize(8);
        this.currentHeight += 1.5;
        this.text(this.transportOrderConfig.paymentAnnotation, this.documentWidth / 2, availableSpace, { align: 'center' });
        this.setDefaultFont();
    };

    private loadDetails = () => {
        this.addPageIfNoSpaceAvailable(25);
        this.currentHeight += 5;

        const x = this.marginLeft;
        const availableWidth = this.documentWidth - this.marginLeft - this.marginRight;

        this.textHeading('ŁADUNEK', x, availableWidth);
        const loadDetailsItemsText = this.transportOrder.loadDetails
            .items
            .map(({ name, value }) => `${name}: ${value}`)
            .join(', ');

        const loadDetailsAdditionalInfoText = this.transportOrder.loadDetails.additionalInfo;

        loadDetailsItemsText && this.textNormal(loadDetailsItemsText, x, availableWidth);
        loadDetailsAdditionalInfoText && this.textNormal(loadDetailsAdditionalInfoText, x, availableWidth);
    }

    private additionalInfo = () => {
        this.addPageIfNoSpaceAvailable(25);
        this.currentHeight += 5;

        const driver = this.transportOrder.driver;
        const vehicle = this.transportOrder.vehicle;
        const additionalInfo = this.transportOrder.additionalInfo.replaceAll('\n', ' ');

        const x = this.marginLeft;
        const availableWidth = this.documentWidth - this.marginLeft - this.marginRight;

        this.textHeading('DODATKOWE INFORMACJE', x, availableWidth);

        const buildText = (...values: Array<[value: string, text: string]>) => (
            values.map(([value, text]) => value ? text : '')
                .filter((item) => item !== '')
                .join(', ')
        );

        const driverText = buildText(
            [driver.name, driver.name],
            [driver.phoneNumber, `tel: ${driver.phoneNumber}`],
            [driver.identityCardNumber, `nr dowodu: ${driver.identityCardNumber}`],
        );

        const vehicleText = buildText(
            [vehicle.carLicensePlate, `auto: ${vehicle.carLicensePlate}`],
            [vehicle.trailerLicensePlate, `naczepa: ${vehicle.trailerLicensePlate}`],
        );

        driverText && this.textNormalWithBoldPrefix('Kierowca', driverText, x, availableWidth);
        vehicleText && this.textNormalWithBoldPrefix('Pojazd', vehicleText, x, availableWidth);
        additionalInfo && this.textNormalWithBoldPrefix('Dodatkowe informacje', additionalInfo, x, availableWidth);
    };

    private loading = () => {
        const hasManyItems = this.transportOrder.loading.length > 1;

        this.transportOrder.loading.forEach((loadingItem, index) => {
            const { date, endDate, time, address, loadingNumber, additionalInfo } = loadingItem;
            const formattedDate = this.getFormattedDate(date);
            const formattedEndDate = this.getFormattedDate(endDate);

            const dateText = formattedDate === formattedEndDate ? formattedDate : `${formattedDate} - ${formattedEndDate}`;
            const dateWithTime = time ? `${dateText}, ${time}` : dateText;

            const formattedAdditionalInfo = additionalInfo.replaceAll('\n', ' ')
                .trim();

            this.currentHeight += 3;

            const headingText = `ZAŁADUNEK${hasManyItems ? ` nr ${index + 1}` : ''}`;

            this.addPageIfNoSpaceAvailable(this.estimateTransportItemHeight(headingText, {
                date: { heading: 'Termin', text: dateWithTime },
                address: { heading: 'Adres', text: address.trim() },
                loadingNumber: loadingNumber ? { heading: 'Numer rozładunku', text: loadingNumber } : undefined,
                additionalInfo: additionalInfo ? {
                    heading: 'Dodatkowe informacje',
                    text: formattedAdditionalInfo,
                } : undefined,
            }) + 2);

            this.transportSectionHeading(headingText);

            this.transportSectionItem(
                { heading: 'Termin', text: dateWithTime },
                { heading: 'Adres', text: address.trim() },
                loadingNumber ? { heading: 'Numer załadunku', text: loadingNumber } : undefined,
                additionalInfo ? { heading: 'Dodatkowe informacje', text: formattedAdditionalInfo } : undefined,
            );
        });
    };

    private unloading = () => {
        const hasManyItems = this.transportOrder.unloading.length > 1;

        this.transportOrder.unloading.forEach((unloadingItem, index) => {
            const { date, endDate, time, address, unloadingNumber, additionalInfo } = unloadingItem;
            const formattedDate = this.getFormattedDate(date);
            const formattedEndDate = this.getFormattedDate(endDate);

            const dateText = formattedDate === formattedEndDate ? formattedDate : `${formattedDate} - ${formattedEndDate}`;
            const dateWithTime = time ? `${dateText}, ${time}` : dateText;

            const formattedAdditionalInfo = additionalInfo.replaceAll('\n', ' ')
                .trim();

            const headingText = `ROZŁADUNEK${hasManyItems ? ` nr ${index + 1}` : ''}`;

            this.currentHeight += 3;
            this.addPageIfNoSpaceAvailable(this.estimateTransportItemHeight(headingText, {
                date: { heading: 'Termin', text: dateWithTime },
                address: { heading: 'Adres', text: address.trim() },
                loadingNumber: unloadingNumber ? { heading: 'Numer rozładunku', text: unloadingNumber } : undefined,
                additionalInfo: additionalInfo ? {
                    heading: 'Dodatkowe informacje',
                    text: formattedAdditionalInfo,
                } : undefined,
            }) + 2);

            this.transportSectionHeading(headingText);

            this.transportSectionItem(
                { heading: 'Termin', text: dateWithTime },
                { heading: 'Adres', text: address.trim() },
                unloadingNumber ? { heading: 'Numer rozładunku', text: unloadingNumber } : undefined,
                additionalInfo ? { heading: 'Dodatkowe informacje', text: formattedAdditionalInfo } : undefined,
            );
        });
    };

    private addPageIfNoSpaceAvailable = (minimumSpace: number) => {
        const hasMinimumSpace = this.documentHeight - this.currentHeight - this.marginTop - this.marginBottom >= minimumSpace;
        if (!hasMinimumSpace) {
            this.document.addPage();
            this.currentHeight = this.marginTop;
        }
    };

    private transportSectionHeading = (heading: string) => {
        const availableWidth = this.documentWidth - this.marginLeft - this.marginRight;
        this.textHeading(heading, this.marginLeft, availableWidth);
    };

    private estimateTransportItemHeight = (heading: string, elements: {
        date: HeadingText,
        address: HeadingText,
        loadingNumber?: HeadingText,
        additionalInfo?: HeadingText
    }) => {
        const availableWidth = this.documentWidth - this.marginLeft - this.marginRight;

        const headingHeight = this.calculateTextHeadingHeight(heading);
        const dateHeight = this.calculateTextNormalHeight(`${elements.date.heading}:  ${elements.date.text}`, availableWidth);
        const addressHeight = this.calculateTextNormalHeight(`${elements.address.heading}:  ${elements.address.text}`, availableWidth);
        const loadingNumberHeight = elements.loadingNumber ? this.calculateTextNormalHeight(`${elements.loadingNumber!.heading}:  ${elements.loadingNumber!.text}`, availableWidth) : 0;
        const additionalInfoHeight = elements.additionalInfo ? this.calculateTextNormalHeight(`${elements.additionalInfo!.heading}:  ${elements.additionalInfo!.text}`, availableWidth) : 0;

        return Math.ceil(headingHeight + dateHeight + addressHeight + loadingNumberHeight + additionalInfoHeight);
    };


    private transportSectionItem = (date: HeadingText, address: HeadingText, loadingNumber?: HeadingText, additionalInfo?: HeadingText) => {
        const availableWidth = this.documentWidth - this.marginLeft - this.marginRight;

        this.textNormalWithBoldPrefix(date.heading, date.text, this.marginLeft, availableWidth);
        this.textNormalWithBoldPrefix(address.heading, address.text, this.marginLeft, availableWidth);
        loadingNumber && this.textNormalWithBoldPrefix(loadingNumber.heading, loadingNumber.text, this.marginLeft, availableWidth);
        additionalInfo && this.textNormalWithBoldPrefix(additionalInfo.heading, additionalInfo.text.trim(), this.marginLeft, availableWidth);
    };

    private logoAndDocumentNumberSection = () => {
        this.addDocumentInfo();
        this.addLogo(); // logo has 93 x 30 space reserved. i do not fit all the space, i rather use full width or full height available

        this.currentHeight = this.marginTop + MAX_LOGO_HEIGHT + 3;
    };

    private contactInfo = () => {
        this.addPageIfNoSpaceAvailable(30);
        const { creator } = this.transportOrder;
        const tmpHeight = this.currentHeight;

        const leftEndHeight = this.contactInfoItem(
            this.marginLeft, (this.documentWidth / 2) - this.marginLeft,
            {
                heading: 'ZLECENIODAWCA',
                name: this.company.name,
                address: this.company.address.trim(),
                nip: this.company.nip,
            },
            {
                heading: 'ZLECENIODAWCA KONTAKT',
                name: creator.name,
                email: creator.email,
                phoneNumber: creator.phoneNumber,
            },
        );

        if (!this.transportOrder.contractor) {
            return;
        }

        this.currentHeight = tmpHeight;
        const contractor: NonNullable<TransportOrder['contractor']> = this.transportOrder.contractor;
        const rightEndHeight = this.contactInfoItem(
            this.documentWidth / 2,
            (this.documentWidth / 2) - this.marginRight,
            {
                heading: 'ZLECENIOBIORCA',
                name: contractor.name,
                address: contractor.address.trim(),
                nip: contractor.nip,
            },
            contractor.contact ? {
                heading: 'ZLECENIOBIORCA KONTAKT',
                name: contractor.contact.name,
                email: contractor.contact.email,
                phoneNumber: contractor.contact.phoneNumber,
            } : undefined,
        );

        this.currentHeight = leftEndHeight > rightEndHeight ? leftEndHeight : rightEndHeight;
    };


    private contactInfoItem = (x: number, availableWidth: number, firstSection: {
        heading: string,
        name: string,
        address: string,
        nip: string
    }, secondSection?: { heading: string, name: string, email: string, phoneNumber: string }) => {
        this.textHeading(firstSection.heading, x, availableWidth);
        this.textNormal(firstSection.name, x, availableWidth);
        this.textNormal(firstSection.address, x, availableWidth);
        this.textNormal(`NIP: ${firstSection.nip}`, x, availableWidth);

        if (secondSection) {
            this.currentHeight += 2;

            this.textHeading(secondSection.heading, x, availableWidth);
            this.textNormal(`${secondSection.name} (${secondSection.email})`, x, availableWidth);
            this.textNormal(`tel: ${secondSection.phoneNumber}`, x, availableWidth);
        }


        return this.currentHeight;
    };

    private textHeading = (text: string, x: number, availableWidth: number) => {
        this.document.setFontSize(12);
        this.document.setFont(this.defaultFont.fontName, this.defaultFont.fontStyle, 'bold');
        const { h: headerHeight } = this.document.getTextDimensions(text);
        this.currentHeight = this.currentHeight + headerHeight + 1.2;
        this.document.text(text, x, this.currentHeight, { maxWidth: availableWidth });
        this.setDefaultFont();
    };

    private calculateTextHeadingHeight = (text: string) => {
        this.document.setFontSize(12);
        this.document.setFont(this.defaultFont.fontName, this.defaultFont.fontStyle, 'bold');
        const { h: headerHeight } = this.document.getTextDimensions(text);
        this.setDefaultFont();
        return headerHeight;
    };

    private textNormal = (text: string, x: number, availableWidth: number, options?: TextOptionsLight) => {
        this.document.setFontSize(10);
        this.text(text, x, availableWidth, options);
        this.setDefaultFont();
    };

    private calculateTextNormalHeight = (text: string, maxWidth: number) => {
        this.document.setFontSize(10);
        const { h: textNormalHeight } = this.document.getTextDimensions(text, { maxWidth });
        this.setDefaultFont();
        return textNormalHeight;
    };

    private text = (text: string, x: number, availableWidth: number, options?: TextOptionsLight) => {
        const { h: lineHeight } = this.document.getTextDimensions(text);
        const { h: textHeight } = this.document.getTextDimensions(text, { maxWidth: availableWidth });
        this.currentHeight = this.currentHeight + lineHeight + 1.2;
        this.document.text(text, x, this.currentHeight, { maxWidth: availableWidth, ...options });
        this.currentHeight += textHeight - (lineHeight + .6);
    };


    private textNormalWithBoldPrefix = (prefix: string, text: string, x: number, availableWidth: number) => {
        const preparedPrefix = `${prefix}: `;

        this.document.setFontSize(10);
        this.document.setFont(this.defaultFont.fontName, this.defaultFont.fontStyle, 'bold');
        const { h: prefixHeight, w: prefixWidth } = this.document.getTextDimensions(preparedPrefix);
        this.document.text(preparedPrefix, x, this.currentHeight + prefixHeight + 1.2);
        this.setDefaultFont();

        const restTextMargin = 3;


        this.document.setFontSize(10);
        const firstLineText = this.document.splitTextToSize(
            text || ' ',
            availableWidth - prefixWidth,
        )[0];

        const restText = text.replace(firstLineText, '').trim();

        this.textNormal(firstLineText, x + prefixWidth, availableWidth);
        restText && this.textNormal(restText, x + restTextMargin, availableWidth);
    };

    private addLogo = () => {
        const availableWidth = (this.documentWidth / 2) - this.marginLeft; // (210 / 2) - 12 = 93;

        const heightToWidthRatio = this.companyLogo.height / this.companyLogo.width;

        const x = this.marginLeft;
        const y = this.marginTop;

        const calculatedWidth = availableWidth;
        const calculatedHeight = calculatedWidth * heightToWidthRatio;

        let width = calculatedWidth;
        let height = calculatedHeight;

        if (calculatedHeight > MAX_LOGO_HEIGHT) { // if image is too tall
            width = (1 / heightToWidthRatio) * MAX_LOGO_HEIGHT;
            height = MAX_LOGO_HEIGHT;
        }

        const topImageMargin = height < MAX_LOGO_HEIGHT ? (MAX_LOGO_HEIGHT - height) / 2 : 0;

        this.document.addImage(this.companyLogo.base64, this.companyLogo.format, x, y + topImageMargin, width, height);
    };

    private addDocumentInfo = () => {
        this.currentHeight = this.marginTop + 2.5;

        const x = this.documentWidth * (3 / 4) - (this.marginRight / 2);

        const availableWidth = this.documentWidth / 2 - this.marginRight;

        const addHeaderText = () => {
            const headerText = 'ZLECENIE TRANSPORTOWE';

            this.document.setFontSize(10);
            const { h: headerHeight } = this.document.getTextDimensions(headerText, { maxWidth: availableWidth });
            this.document.text(headerText, x, this.currentHeight + headerHeight, {
                maxWidth: availableWidth,
                align: 'center',
            });

            this.currentHeight += headerHeight + 10;
            this.setDefaultFont();
        }

        const addOrderId = () => {
            this.document.setFont(this.defaultFont.fontName, this.defaultFont.fontStyle, 'bold');
            this.document.text(this.transportOrder.orderId, x, this.currentHeight, {
                maxWidth: availableWidth,
                align: 'center',
            });

            this.setDefaultFont();
        }

        const addCreationDate = () => {
            const creationDateText = `Data: ${this.getFormattedDate(this.transportOrder.creationDate)}`;

            this.document.setFontSize(9);
            const { h: headerHeight } = this.document.getTextDimensions(creationDateText, { maxWidth: availableWidth });
            const marginTop = 4;

            const x = this.documentWidth * (3 / 4) - (this.marginRight / 2);
            const y = this.currentHeight + headerHeight + marginTop;

            this.document.text(creationDateText, x, y, { maxWidth: availableWidth, align: 'center' });
            this.setDefaultFont();
        }

        addHeaderText();
        addOrderId();
        addCreationDate();
    };

    private setDefaultFont = () => {
        const { fontName, fontStyle } = this.defaultFont;
        this.document.setFont(fontName, fontStyle, 'normal');
        this.document.setFontSize(this.defaultFontSize);
        this.document.setTextColor(this.defaultTextColor);
    };

    private getFormattedDate = (date: Date) => date.toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    private layoutLines = () => {
        this.document.line(this.marginLeft, 0, this.marginLeft, this.documentHeight); // left-margin
        this.document.line(this.documentWidth - this.marginRight, 0, this.documentWidth - this.marginRight, this.documentHeight); // right-margin
        this.document.line(0, this.marginTop, this.documentWidth, this.marginTop); // tight-margin
        this.document.line(this.documentWidth / 2, 0, this.documentWidth / 2, this.documentHeight); // middle

        this.document.line(0, this.marginTop + 30, this.documentWidth, this.marginTop + 30); // logo-end
    };

}
